import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.map.js";
import { defineComponent, reactive, ref } from 'vue';
import moment from 'moment';
import { map, toInteger, chain } from 'lodash';
import { useElementVisibility } from '@vueuse/core';
import { dauerFilter } from '@rose/common-ui';
import { hasAnyR4cFeature, hasMetricsFeature } from '@rose/base';

function createMetricsChart(chartdata) {
  if (!chartdata) {
    return {
      points: [],
      options: {}
    };
  }

  var points = map(chartdata, function (d) {
    return {
      x: moment(d.time).toDate(),
      y: toInteger(d.count),
      color: d.weekday === 6 || d.weekday === 7 ? '#1f75aa' : '#319ee1'
    };
  });

  var tooltipFormatterInterval = function tooltipFormatterInterval(point) {
    return "ca. ".concat(dauerFilter.filters.dauer(point.y, 'm'), " Minuten Nutzung am ").concat(moment(point.x).format('dd DD.MM.YYYY'));
  };

  var highchartsChartOptions = reactive({
    title: {
      text: '' // Set an empty string to remove the title

    },
    credits: {
      enabled: false // Set enabled to false to remove the Highcharts logo

    },
    series: [{
      data: points,
      type: 'column'
    }],
    legend: {
      enabled: false
    },
    tooltip: {
      formatter: function formatter() {
        return tooltipFormatterInterval(this);
      },
      shared: true,
      useHTML: true
    },
    yAxis: {
      title: {
        text: 'Nutzung pro Tag'
      },
      tickInterval: 60,
      labels: {
        formatter: function formatter() {
          return this.value / 60 + 'h'; // Convert minutes to hours for y-axis labels
        }
      },
      plotLines: [{
        value: 300,
        // 5 hours in minutes (5 * 60)
        width: 2,
        dashStyle: 'Dash',
        zIndex: 4,
        // Ensure the line is above the columns
        label: {
          text: '5 Stunden',
          align: 'left',
          style: {
            color: 'gray'
          }
        }
      }]
    },
    xAxis: {
      type: 'datetime',
      title: {
        text: 'letzter Monat'
      }
    },
    plotOptions: {
      column: {
        borderRadius: 3,
        pointPadding: 0.0,
        // Adjust the padding between columns
        groupPadding: 0.0 // Adjust the padding between groups of columns

      }
    }
  });
  return {
    points: points,
    highchartsChartOptions: highchartsChartOptions
  };
}

export default defineComponent({
  props: {
    chartData: {
      type: Object,
      "default": function _default() {
        return null;
      }
    },
    features: {
      type: Object,
      "default": function _default() {
        return [];
      }
    }
  },
  setup: function setup(props) {
    var _a, _b, _c, _d; // month chart


    var _createMetricsChart = createMetricsChart((_b = (_a = props.chartData) === null || _a === void 0 ? void 0 : _a.metrics) === null || _b === void 0 ? void 0 : _b.lastmonth.chart),
        metricsMonthPoints = _createMetricsChart.points,
        metricsMonthChartOptionsNew = _createMetricsChart.highchartsChartOptions;

    var _createMetricsChart2 = createMetricsChart((_d = (_c = props.chartData) === null || _c === void 0 ? void 0 : _c.metrics) === null || _d === void 0 ? void 0 : _d.lastyear.chart),
        metricsYearPoints = _createMetricsChart2.points,
        metricsYearChartOptionsNew = _createMetricsChart2.highchartsChartOptions;

    var target = ref(null);
    var targetIsVisible = useElementVisibility(target);
    return {
      target: target,
      targetIsVisible: targetIsVisible,
      hasMetricsChartData: chain(metricsYearPoints).map(function (p) {
        return p.y;
      }).filter(function (val) {
        return val !== 0;
      }).size().value() > 0,
      metricsMonthPoints: metricsMonthPoints,
      metricsYearPoints: metricsYearPoints,
      metricsMonthChartOptionsNew: metricsMonthChartOptionsNew,
      metricsYearChartOptionsNew: metricsYearChartOptionsNew
    };
  },
  data: function data() {
    return {
      chartDataType: 'metrics'
    };
  },
  computed: {
    hasR4C: function hasR4C() {
      return hasAnyR4cFeature({
        lizenzen: map(this.features, function (f) {
          return f.key;
        })
      });
    },
    hasMetrics: function hasMetrics() {
      return hasMetricsFeature({
        lizenzen: map(this.features, function (f) {
          return f.key;
        })
      });
    }
  },
  methods: {
    log: function log() {
      console.log('chartinfos', this.metricsMonthPoints, this.metricsYearPoints);
    }
  }
});